import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/style/reset.scss'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import 'lib-flexible/flexible'
import './utils/flexible.js'

import 'swiper/swiper.min.css';

import { createPinia } from 'pinia'
import { registerStore } from './store/app.js'
import MyDialog from '@/components/MyDialog'
const app = createApp(App)
app.component('MyDialog', MyDialog)
app.use(createPinia())
// 注册 Pinia
registerStore()


app.use(ElementPlus)
// app.config.globalProperties.addChineseUnit = addChineseUnit;
// app.provide('mitter', mitter)
// app.provide('timeSection', timeSection)
// app.provide('cancel', cancel)
app.use(router).mount('#app')
