import { defineStore, createPinia } from 'pinia'
import { getRouterApi } from '@/api/api'
import { generateRoutes } from '@/router'

export const appStore = defineStore('appStore', {
  state: () => ({
    menuRoutes: [],
    routes: [],
    tenantId: '000000',
    currentRoute: null,
    configInfo: {},
    scrollFlag: false
  }),
  actions: {
    async getMenuRoutes() {
      const { data } = await getRouterApi(this.tenantId)
      const routes = generateRoutes(data)
      this.menuRoutes = routes
      this.currentRoute = routes[0]
      // 常量菜单
      // const constantRoutes = generateRoutes(constantMenu)
      // this.menuRoutes.push(...constantRoutes)


      return this.menuRoutes
    },
    setRoutes(routers) {
      this.routes = routers
    },
    setCurrentRoute(route) {
      this.currentRoute = route
    },
    setConfigInfo(info) {
      this.configInfo = info
    },
    setScrollFlag(flag) {
      this.scrollFlag = flag
    }
  }
})

const store = createPinia()
// const store: any = {}
export const registerStore = () => {
  store.appStore = appStore()
}

export default store