import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import store from "@/store/app.js";
const home = () => import(/* webpackChunkName: "home" */ '../views/home') //首页主路由
const home1 = () => import(/* webpackChunkName: "home" */ '../views/home/home.vue') //首页主路由

const routes = [
    {
        path: '/iframe/:query?',
        component: () => import('@/router/Iframe.vue')
    },

    {
        path: '/goods',
        name: 'goods',
        component: () => import('@/views/indexPage'),
        redirect: 'goodsDetail',
        children: [
            {
                path: '/goodsDetail',
                hidden: true,
                component: () => import('@/views/goods/index.vue')
            },
            {
                path: '/goodsList',
                name: '商品定制',
                hidden: true,
                component: () => import('@/views/goods/list.vue')
            }
        ]
    },
    // {
    //     path: '/',
    //     component: () => import('@/views/indexPage'),
    //     redirect: 'home',
    //     children: [
    //         {
    //             path: 'home',
    //             name: '首页',
    //             component: () => import('@/views/home'),
    //             meta: { title: '首页', icon: 'icon-home' }
    //         },
    //         {
    //             path: 'allProducts',
    //             name: '所有品类',
    //             component: home1,//() => import('@/views/allProducts'),
    //             meta: { title: '所有品类', icon: 'icon-allProducts' },
    //         },
    //         {
    //             path: 'patternDesign',
    //             name: '图案设计',
    //             component: () => import('@/views/patternDesign'),
    //             meta: { title: '图案设计', icon: 'icon-patternDesign' },
    //         },
    //         {
    //             path: 'printing',
    //             name: '印花工艺',
    //             component: () => import('@/views/printing'),
    //             meta: { title: '印花工艺', icon: 'icon-printing' },
    //         },
    //         {
    //             path: 'normalProblem',
    //             name: '常见问题',
    //             component: () => import('@/views/normalProblem'),
    //             meta: { title: '常见问题', icon: 'icon-normalProblem' },
    //         },
    //         {
    //             path: 'clients',
    //             name: '客户案例',
    //             component: () => import('@/views/clients'),
    //             meta: { title: '客户案例', icon: 'icon-clients' },
    //         },
    //         {
    //             path: 'customize',
    //             name: '定制攻略',
    //             component: () => import('@/views/customize'),
    //             meta: { title: '定制攻略', icon: 'icon-customize' },
    //         },
    //         {
    //             path: 'contactUs',
    //             name: '关于我们',
    //             component: () => import('@/views/contactUs'),
    //             meta: { title: '关于我们', icon: 'icon-contactUs' },
    //         }
    //     ]
    // },
    // 404 page must be placed at the end !!!
    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true
    },
    // 404 page must be placed at the end !!!
    { path: '/:pathMatch(.*)', redirect: '/404', hidden: true }
]

let router = createRouter({
    // mode: 'history',
    mode: 'hash',
    // history: createWebHistory('/'),
    history: createWebHashHistory("/"),
    routes
})

// 根据菜单列表，生成路由数据
export const generateRoutes = (menuList) => {
    const routerList = [{
        path: '/',
        component: () => import('@/views/indexPage'),
        // redirect: 'home',
        children: []
    }]
    menuList.forEach((menu, parent) => {
        let component
        let path
        if (menu.children && menu.children.length > 0) {
            component = () => import('@/views/indexPage/index.vue')
            path = menu.path == '/' ? '/' : '/' + menu.path
        } else if (path == '/') {
            component = () => import('@/views/' + menu.component) //
            path = '/' + menu.path
        } else {
            component = () => import('@/views/' + menu.component) //
            path = menu.path
        }
        const route = {
            path: path,
            name: menu.menuName, //pathToCamel(path)
            component: component,
            children: [],
            meta: {
                ...menu.meta,
                url: menu.path,
                modelId: menu.menuId,
                icon: menu.icon,
                secondIcon: menu.secondIcon,
                thirdIcon: menu.thirdIcon,
                title: menu.menuName,
                visible: menu.visible,
                parentId: menu.parentId
            }
        }

        // 有子菜单的情况
        if (menu.children && menu.children.length > 0) {
            route.children?.push(...generateRoutes(menu.children))
        }
        routerList[0].children.push(route)

    })
    return routerList
}
let num = 0
// 路由跳转前
router.beforeEach(async (to, from, next) => {
    if (to.path == "/404" && num >= 5) {
        console.log(1)
        next()
    } else {
        console.log(2)
        // 判断是否已经拿到路由
        if (!store.appStore.menuRoutes.length) {
            console.log(3)
            try {
                if (num < 5) {
                    num++;
                    const menuRoutes = await store.appStore.getMenuRoutes()
                    menuRoutes.forEach(item => {
                        router.addRoute(item)
                    })
                    console.log(to)
                    if (to.path) {
                        // const menuPath = menuRoutes[0].children.map(item => item.path).concat(['goods','goodsList']);
                        // let toPath = to.path.replace('/', '')
                        // console.log(menuPath, toPath)
                        // if (menuPath.includes(toPath) || menuPath.includes('/' + toPath)) {
                        //     next(to)
                        // } else if (to.redirectedFrom) {
                        //     toPath = to.redirectedFrom.path.replace('/', '')
                        //     if (menuPath.includes(toPath) || menuPath.includes('/' + toPath)) {
                        //         next(to.redirectedFrom)
                        //     } else {
                        //         next({ path: '/' })
                        //     }
                        // } else {
                        //     next({ path: '/' })
                        // }
                        if (to.path !== '/404') {
                            next({ ...to, replace: true })
                        } else {
                            if (to.redirectedFrom) {
                                const menuPath = menuRoutes[0].children.map(item => item.path).concat(['goods', 'goodsDetail', 'goodsList']);
                                const toPath = to.redirectedFrom.path.replace('/', '')
                                if (menuPath.includes(toPath) || menuPath.includes('/' + toPath)) {
                                    next(to.redirectedFrom)
                                } else {
                                    next({ path: '/' })
                                }

                            } else {
                                next({ path: '/' })
                            }
                        }
                    } else {
                        next('/404')
                    }
                }
            } catch (error) {
                // 请求异常，则跳转到404
                next('/404')
                return Promise.reject(error)
            }
        } else {
            next()
        }
    }
})

export default router
