import axiosIns from "./axios-ins";
import * as url from "./url";

export default function getWebMediumList(params) {
    return axiosIns.post(url.getWebMediumList, params)
}
// 图片集合接口
export function getWebListPicture(params) {
    return axiosIns.post('/cms/menu/webListPicture', params)
}
// 路由列表接口
export const getRouterApi = (tenantId) => {
    return axiosIns.get('/cms/menu/webList/' + tenantId)
}

// 富文本接口
export const getWebListRich = (params) => {
    return axiosIns.post('/cms/menu/webListRich', params)
}

// 卡片接口
export const getWebListCard = (params) => {
    return axiosIns.post('/cms/menu/webListCard', params)
}

// 网页设置
export const getConfigInfo = (tenantId) => {
    return axiosIns.get('/cms/config/getConfigInfo/' + tenantId)
}

// 商品分类
export const getAllTypeList = (tenantId) => {
    return axiosIns.get('/shop/shopType/webList/' + tenantId)
}

// 获取商品分类到首页
export const webListShow = (tenantId) => {
    return axiosIns.get('/shop/shopType/webListShow/' + tenantId)
}

//商品接口 分页
export const getProductPage = (params) => {
    return axiosIns.get('/shop/shop/webListPage', { params })
}
//商品接口 不分页
export const getProductByTag = (params) => {
    return axiosIns.get('/shop/shop/webList', { params })
}

//商品详情接口 不分页
export const getProductDetail = (shopId) => {
    return axiosIns.get(`/shop/shop/queryOne/${shopId}`)
}

// 获取文本组件接口
export const getWebListText = (params) => {
    return axiosIns.post('/cms/menu/webListText', params)
}

// 新增客户预约或者查询
export const appointment = (params) => {
    return axiosIns.post('/cms/appointment', params)
}
// 查询所有省市区数据
export const allAddressList = (params) => {
    return axiosIns.get('/system/address/allList', { params })
}

// 查看常见问题类型
export const getQuslistType = (params) => {
    return axiosIns.get('/cms/questionGroup/listAll', { params })
}

// 常见问题列表
export const externalList = (params) => {
    return axiosIns.get('/cms/question/externalList', { params })
}
// 字典类型查询
export const getDictType = (dictType) => {
    return axiosIns.get(`/system/dict/data/type/${dictType}`)
}
// 客户案例列表
export const getCustomerCase = (params) => {
    return axiosIns.get(`/cms/customerCase/list`, { params })
}

