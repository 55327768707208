import axios from "axios";
import * as url from "./url";
import { tansParams } from '@/utils';
import router from "@/router"; // 用于在判断错误时的重定向页面，例如404页面等
// const qs = require("qs");
// const CancelToken = axios.CancelToken;
import qs from "qs";

// import {useRouter} from "vue-router";
//
// const router = useRouter()
export const axiosIns = axios.create({
    baseURL: url.BASE_URL,
    transformResponse: [function (data) {
        return JSON.parse(data);
    }],
    // `headers` 是即将被发送的自定义请求头
    headers: { 'Content-Type': 'application/json;charset=utf-8' },
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'}
});
window.axiosCancel = []
// 添加请求拦截器
// 请求拦截器
axiosIns.interceptors.request.use(
    (config) => {
        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?' + tansParams(config.params);
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }

        if (config.method === 'post' || config.method === 'put') {
            config.data = typeof config.data === 'object' ? JSON.stringify(config.data) : config.data
        }
        // FormData数据去请求头Content-Type
        if (config.data instanceof FormData) {
            delete config.headers['Content-Type'];
        }

        return config;
    },
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);
// 添加响应拦截器
axiosIns.interceptors.response.use(
    function (response) {

        return response.data;
    },
    function (error) {
        // console.log(error);
        //  1.
        //  2.需要重定向到错误页面
        const errorInfo = error.response;
        if (errorInfo) {
            // console.log(errorInfo.status, "errorInfo");
            if (errorInfo.status === 401) {

            }
        }
        return error; // 在调用的那边可以拿到(catch)你想返回的错误信息
    }
);

export default axiosIns;
