export const getImgHeight = (img, noUnit) => {
  const naturalWidth = img.naturalWidth;
  const naturalHeight = img.naturalHeight;
  const imgWidth = img.width;
  const proportion = naturalWidth / naturalHeight;
  return imgWidth / proportion + (!noUnit ? "px" : 0);
}

// 替换外链参数
export const replaceLinkParam = (url) => {
  return url.replace('{{apiUrl}}', process.env.VUE_APP_URL)
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export const tansParams = (params) => {
  let result = '';
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    const part = encodeURIComponent(propName) + '=';
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== '' && typeof value[key] !== 'undefined') {
            const params = propName + '[' + key + ']';
            const subPart = encodeURIComponent(params) + '=';
            result += subPart + encodeURIComponent(value[key]) + '&';
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&';
      }
    }
  }
  return result;
};
// 只输入中英文数字
export const numEnCNReg = (str) => {
  var emailReg = /^[\u4e00-\u9fa5a-zA-Z0-9\-]+$/;
  return emailReg.test(str);
}
// 手机号码校验
export const phoneReg = (str) => {
  var emailReg = /^1[3456789]\d{9}$/;
  return emailReg.test(str);
}